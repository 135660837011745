.react-datepicker__day--today {
    font-weight: normal;
}

.react-datepicker__day--highlighted {
    font-weight: bold;
    background-color: transparent;
    color: black;
}

.react-datepicker__day--highlighted:hover {
    background-color: #f0f0f0;
}